var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',{staticClass:"d-flex align-center justify-space-between"},[_c('span',[_vm._v(" Tags "),(_vm.tagList)?_c('span',{staticClass:"text--disabled text-subtitle-1"},[_vm._v("("+_vm._s(_vm.tagList.length)+")")]):_vm._e()]),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary","depressed":"","rounded":""},on:{"click":function($event){(_vm.isOpenDialog = true)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_vm._v("ADD TAG ")],1)],1),(!_vm.tagList)?_c('v-skeleton-loader',{attrs:{"type":"list-item-three-line, list-item-three-line, list-item-three-line","loading":true}}):_vm._e(),(_vm.tagList)?_c('v-data-table',{attrs:{"headers":_vm.tagListHeaders,"items-per-page":50,"footer-props":{
      itemsPerPageOptions: [10, 25, 50]
    },"sort-desc":[false],"sort-by":['name'],"items":_vm.tagList,"dense":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
    var item = ref.item;
return [_c('div',[_c('p',{staticClass:"body-2 mb-0"},[_vm._v(" "+_vm._s(item.tid)+" ")])])]}},{key:"item.tagname",fn:function(ref){
    var item = ref.item;
return [_c('div',[_c('p',{staticClass:"body-2 mb-0"},[_vm._v(" "+_vm._s(item.name)+" ")])])]}},{key:"item.cn",fn:function(ref){
    var item = ref.item;
return [_c('div',[_c('p',{staticClass:"body-2 mb-0"},[_vm._v(" "+_vm._s(item.cn)+" ")])])]}},{key:"item.en",fn:function(ref){
    var item = ref.item;
return [_c('div',[_c('p',{staticClass:"body-2 mb-0"},[_vm._v(" "+_vm._s(item.en)+" ")])])]}},{key:"item.zhTw",fn:function(ref){
    var item = ref.item;
return [_c('div',[_c('p',{staticClass:"body-2 mb-0"},[_vm._v(" "+_vm._s(item.zhTw)+" ")])])]}},{key:"item.jp",fn:function(ref){
    var item = ref.item;
return [_c('div',[_c('p',{staticClass:"body-2 mb-0"},[_vm._v(" "+_vm._s(item.jp)+" ")])])]}},{key:"item.btn",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center py-3"},[_c('v-btn',{staticClass:"ml-4",attrs:{"loading":item.removeLoading,"color":"error","depressed":"","rounded":"","x-small":""},on:{"click":function($event){return _vm.remove(item)}}},[_vm._v(" REMOVE ")])],1)]}}],null,true)}):_vm._e()],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.isOpenDialog),callback:function ($$v) {_vm.isOpenDialog=$$v},expression:"isOpenDialog"}},[_c('v-card',{attrs:{"translate":"no"}},[_c('v-card-title',[_c('span',{staticClass:"headline"})]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"my-2 title",attrs:{"label":"Tag Name","dense":""},model:{value:(_vm.tagName),callback:function ($$v) {_vm.tagName=$$v},expression:"tagName"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"my-2 title",attrs:{"dense":"","label":"中文名称"},model:{value:(_vm.tagCn),callback:function ($$v) {_vm.tagCn=$$v},expression:"tagCn"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"my-2 title",attrs:{"dense":"","label":"English Name"},model:{value:(_vm.tagEn),callback:function ($$v) {_vm.tagEn=$$v},expression:"tagEn"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"my-2 title",attrs:{"dense":"","label":"繁体中文"},model:{value:(_vm.tagZhTw),callback:function ($$v) {_vm.tagZhTw=$$v},expression:"tagZhTw"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"my-2 title",attrs:{"dense":"","label":"日本語"},model:{value:(_vm.tagJp),callback:function ($$v) {_vm.tagJp=$$v},expression:"tagJp"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.isOpenDialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"loading":_vm.addLoading,"color":"success"},on:{"click":_vm.add}},[_vm._v(" Add ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }