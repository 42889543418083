import { post } from './http'

export function tagList(params) {
  return post('/api/tag/list.json', params)
}

export function tagAdd(params) {
  return post('/api/tag/add.json', params)
}

export function tagRemove(params) {
  return post('/api/tag/remove.json', params)
}

export function tagHotTags(params) {
  return post('/api/tag/hotTags.json', params)
}


export function tagAuthorTags(params) {
  return post('/api/tag/authorTags.json', params)
}
