<template>
  <div>

    <v-card>
      <v-card-title class="d-flex align-center justify-space-between">
          <span>
            Tags <span class="text--disabled text-subtitle-1" v-if="tagList">({{tagList.length}})</span>
          </span>
          <v-btn
            class="ml-4"
            color="primary"
            depressed
            rounded @click="(isOpenDialog = true)"
            >
            <v-icon left>{{icons.mdiPlus}}</v-icon>ADD TAG
          </v-btn>
      </v-card-title>
      
      <v-skeleton-loader
                type="list-item-three-line, list-item-three-line, list-item-three-line"
                :loading="true"
                v-if="!tagList"
      >
    </v-skeleton-loader>
    <v-data-table
      v-if="tagList"
      :headers="tagListHeaders"
      :items-per-page="50"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50]
      }"
      :sort-desc="[false]"
      :sort-by="['name']"
      :items="tagList"
      dense
    >
      <template #[`item.id`]="{ item }">
        <div>
          <p class="body-2 mb-0">
            {{ item.tid }}
          </p>
        </div>
      </template>
      <template #[`item.tagname`]="{ item }">
        <div>
          <p class="body-2 mb-0">
            {{ item.name }}
          </p>
        </div>
      </template>
      <template #[`item.cn`]="{ item }">
        <div>
          <p class="body-2 mb-0">
            {{ item.cn }}
          </p>
        </div>
      </template>
      <template #[`item.en`]="{ item }">
        <div>
          <p class="body-2 mb-0">
            {{ item.en }}
          </p>
        </div>
      </template>
      <template #[`item.en`]="{ item }">
        <div>
          <p class="body-2 mb-0">
            {{ item.en }}
          </p>
        </div>
      </template>

      <template #[`item.zhTw`]="{ item }">
        <div>
          <p class="body-2 mb-0">
            {{ item.zhTw }}
          </p>
        </div>
      </template>

      <template #[`item.jp`]="{ item }">
        <div>
          <p class="body-2 mb-0">
            {{ item.jp }}
          </p>
        </div>
      </template>

      <template #[`item.btn`]="{ item }">
        <div class="d-flex align-center py-3">
          <v-btn
            :loading="item.removeLoading"
            color="error"
            class="ml-4"
            depressed
            rounded
            x-small
            @click="remove(item)"
          >
            REMOVE
          </v-btn>
        </div>
      </template>
    </v-data-table>
    </v-card>
    
  
    <v-dialog
      v-model="isOpenDialog"
      persistent
      max-width="500px"
    >
      <v-card translate="no">
        <v-card-title>
          <span class="headline"></span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="tagName"
                  class="my-2 title"
                  label="Tag Name"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="tagCn"
                  class="my-2 title"
                  dense
                  label="中文名称"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="tagEn"
                  class="my-2 title"
                  dense
                  label="English Name"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="tagZhTw"
                  class="my-2 title"
                  dense
                  label="繁体中文"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="tagJp"
                  class="my-2 title"
                  dense
                  label="日本語"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="isOpenDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            :loading="addLoading"
            color="success"
            @click="add"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiClose,
  mdiPlus
} from '@mdi/js'
import { tagList, tagAdd, tagRemove } from '../request/tag'

export default {
  components: {
  },
  data() {
    return {
      icons: {
        mdiClose,
        mdiPlus
      },
      fileData: null,
      tagListHeaders: [
        { text: 'ID', value: 'id', sortable: false },
        { text: 'NAME', value: 'name', sortable: true},
        { text: 'CN', value: 'cn', sortable: false },
        { text: 'EN', value: 'en', sortable: false },
        { text: 'ZHTW', value: 'zhTw', sortable: false },
        { text: 'JP', value: 'jp', sortable: false },
        { text: '', value: 'btn', sortable: false },
      ],
      tagList: null,
      isOpenDialog: false,
      tagName: null,
      tagCn: null,
      tagEn: null,
      tagZhTw:null,
      tagJp: null,
      addLoading: false,
      removeLoading: false,
    }
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      await tagList().then(res => {
        if (res.status == 0) {
          res.data.forEach(item => {
            item.removeLoading = false
          })
          this.tagList = res.data
        } else {
          this.$q.error(res.message)
        }
      }).catch(err => {
        this.$q.error('Failed to get data!')
        console.log(err)
      })
    },
    async add() {

      //confirm input and trim 
      if (!this.tagName || !this.tagCn || !this.tagEn || !this.tagZhTw || !this.tagJp) {
        this.$q.error('Please input all fields')
        return false
      }

      this.addLoading = true
      const params = {
        cn: this.tagCn.trim(),
        en: this.tagEn.trim(),
        zhTw: this.tagZhTw.trim(),
        jp: this.tagJp.trim(),
        name: this.tagName.trim(),
      }

      await tagAdd(params).then(res => {
        if (res.status == 0) {
          this.getList()
          this.addLoading = false
          this.isOpenDialog = false
          this.$q.success('Add tag success')
        }
      }).catch(err => {
        this.addLoading = false
        this.$q.success('Add tag Failed')
        console.log(err)
      })
    },
    async remove(item) {
      if (!confirm('confirm remove this tag?')) {
        return false
      }
      item.removeLoading = true
      const params = {
        name: item.name,
      }
      await tagRemove(params).then(res => {
        console.log(res)
        if (res.status == 0) {
          this.getList()
          item.removeLoading = false
          this.$q.success('Remove tag success')
        }
      }).catch(err => {
        item.removeLoading = false
        this.$q.success('Remove tag Failed')
        console.log(err)
      })
    },
  },
}
</script>

<style scoped>
.dropbox {
  border: 1px dashed #4C8BF5;
  border-radius: 12px;
  width: 100%;
  cursor: pointer;
}
</style>
